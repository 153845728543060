let userData = null
import {
	parseTime
} from '@/utils/validate'
import { resolve } from 'path-browserify'
const imClient = {
	data() {
		return {
			mimc_appId: '2882303761518445444',
			mimc_appSecret: 'F9417EZroFiz1DTdlwy1nQ==',
			mimc_appKey: '5521844517444',
			mimc_appAccount: localStorage.getItem('uid'),
			uid: localStorage.getItem('uid'),
			messages: []
		}
	},
	methods: {
		login() {
            if(this.imLogin()) return
			/* eslint-disable */
			userData = new MIMCUser(this.mimc_appId, this.uid)
			// this.$store.commit('initToken', this.userData.getToken())
			userData.registerP2PMsgHandler(this.receiveP2PMsg) //接收单聊消息回调
			userData.registerFetchToken(this.fetchMIMCToken) //获取token回调
			userData.registerStatusChange(this.statusChange) //登录结果回调
			userData.registerServerAckHandler(this.serverAck) //发送消息后，服务器接收到消息ack的回调
			userData.registerDisconnHandler(this.disconnect) //连接断开回调
			userData.registerPullNotificationHandler(this.pullNotificationHandler) //拉取离线消息回调
			userData.login()
		},
        imLogin() {
            if (userData !== null) {
                return userData.isLogin()
            } else {
                return false
            }
        },
        //退出登录 isOut是否退出系统
        logout() {
            if (userData !== null) {
                userData.logout();
                userData = null
            }
        },
		async getImHistory(toAccount, fromAccount, toUrl,msgCount) {
            const _token = await this.getTokenInfo()
            return new Promise((resolve)=>{
                const _url = 'https://mimc.chat.xiaomi.net/api/msg/p2p/queryOnCount'
                const _parameter = {
                    toAccount: toAccount,
                    fromAccount: fromAccount,
                    utcToTime: new Date().getTime(),
                    count: msgCount,
                }
                this.$http.his(_url, _parameter, _token).then(res => {
                    if (res.code !== 200) return
                    if (res.data.messages) {
                        var list = res.data.messages || []
                        var temp = []
                        list.forEach(item => {
                            if (item.payload) {
                                /* eslint-disable */
                                let msg = JSON.parse(Base64.decode(item.payload))
                                var c = null
                                try {
                                    c = JSON.parse(msg.payload) //内容
                                    if (c != '') {
                                        const _time = new Date(parseInt(msg.timestamp))
                                        let message1 = c
                                        let obj = {}
                                        obj.msgId = msg.msgId
                                        obj.bizType = item.bizType
                                        obj.touid = message1.touid || message1.companyId||fromAccount
                                        obj.isSelf = localStorage.getItem('uid') == item.fromAccount
                                        obj.uid = message1.uid||toAccount
                                        obj.state = '0'
                                        switch (item.bizType) {
                                            case 'IMG':
                                                if (!message1.imgUrl) {
                                                    return
                                                }
                                                if (!message1.width) {
                                                    return
                                                }
                                                if (message1.imgUrl.indexOf('blob') > -1) {
                                                    return
                                                }
                                                obj.imgUrl = message1.imgUrl
                                                obj.width = message1.width
                                                obj.height = message1.height
                                                break
                                            case 'EMO':
                                                obj.url = message1.url
                                                break
                                            case 'request': //交换请求
                                                obj = JSON.parse(JSON.stringify(message1))
                                                obj.toAccout = message1.toAccout
                                                obj.requestType = message1.requestType
                                                obj.msg = message1.toAccout === localStorage
                                                    .getItem(
                                                        'uid') ?
                                                    `对方发来交换${message1.requestType === 1?'电话':'微信'}请求` :
                                                    message1
                                                    .msg
                                                obj.noAvatar = true
                                                break
                                            case 'card': //交换信息
                                                if (message1.toAccout !== localStorage.getItem(
                                                        'uid')) {
                                                    return
                                                }
                                                obj = message1
                                                obj.noAvatar = true
                                                break
                                            case 'TEXT_RECALL': //撤回消息
                                            case 'text_recall':
                                                //1、找到要撤回的原消息，让原来的消息不显示在列表
                                                //2、bizType是撤回类型的消息，组装json数据并显示在中间
                                                const recall_index = temp.findIndex(v => {
                                                    return v.msgId == message1.recall_msgId
                                                })
                                                if (recall_index == -1) return
                                                obj.noAvatar = true //不显示头像，显示在中间
                                                obj.msg = !temp[recall_index].isSelf ? `对方撤回了一条消息` : `你撤回了一条消息`
                                                temp.splice(recall_index, 1)
                                            break
                                            case 'TEXT_READ'://已读消息
                                                const read_index = temp.findIndex(v => {
                                                    return v.msgId == message1.read_msgId
                                                })
                                                if(read_index>-1){
                                                    temp[read_index]['state'] = '1'//设置为已读
                                                }
                                            return
                                            default:
                                                if (!message1.msg && !message1.lastMsg) {
                                                    return
                                                }
                                                obj.msgContent = message1.msg || message1.lastMsg
                                                break
                                        }
                                        if (!obj.noAvatar) { //不显示头像
                                            obj.avatar = obj.isSelf ?
                                                `${window.global.avatarUrl}avatars.php?uid=${this.uid}${(this.utype === '1' ?'&type=1' : '')}` :
                                                toUrl
                                        }
                                        obj.time = parseTime(_time)
                                        temp.push(obj)
                                    }
                                } catch (d) {
                                    //json解析异常数据
                                }
                            }
                        })
                        //控制双方是否都回复
                        const _index = temp.some(v => v.isSelf === true)
                        const _index1 = temp.some(v => v.isSelf === false)
                        const _unreadIds = temp.filter(v => v.state == '0' && !v.isSelf && v.msgId) //查出未读消息并组成数组
                        this.$store.commit('sexExchange', {
                            isExchange: _index && _index1
                        })
                        this.eventBus.$emit('historySuccess', temp)
                        resolve({code:200})
                        if(_unreadIds.length == 0) return
                        this.eventBus.$emit('setUnreadMsgList', _unreadIds)
                    }
                })
            })
		},
		//收消息
		receiveP2PMsg(message) {
            const _bType = message.getBizType()
            const _payload = JSON.parse(message.getPayload())
			var msg = JSON.parse(_payload.payload)
            //用户A接收到已读消息,将消息设置为已读
            if(_bType == 'TEXT_READ'){
                this.eventBus.$emit('msgRead',msg.read_msgId||'')
                return
            }
			let obj = msg
			obj.flag = localStorage.getItem('utype') === '1'
			obj.msgContent = msg.msg || msg.lastMsg
			obj.avatar = obj.avatar
			obj.type = '未读'
			obj.isSelf = false
            obj.msgId = _payload.msgId
            obj.fromAccount = message.getFromAccount()
            const _env = localStorage.getItem('utype') == '2' ? 'eventBusName2' :'eventBusName1'
			this.eventBus.$emit(_env,obj)
           
		},
        //发送已读消息给A
        sendImReadMsg(toUser, msgId) {
            if (!msgId) {
                this.$message.error('已读消息ID为空')
                return
            }
            var jsonMsg = String(JSON.stringify({
                version: 0,
                msgId: String((new Date()).valueOf()),
                timestamp: String((new Date()).valueOf()),
                payload: JSON.stringify({
                    read_msgId: msgId
                })
            }))
            try {
                var packetId = userData.sendMessage(toUser, jsonMsg, 'TEXT_READ')
            } catch (err) {
                console.log("sendMessage fail, err=" + err)
                this.$message.error('token过期，请重新刷新页面')
            }
        },
		//获取token
		getTokenInfo() {
            return new Promise((resolve)=>{
                if (!userData) {
                    this.$msg('获取ImToken失败', 'warning')
                    resolve('')
                    return ''
                }
                resolve(userData.getToken())
            })
		},
		//发消息
		async xiaomiSendMsg(toUser, message, bizType,isHello) {
			var jsonMsg = String(
				JSON.stringify({
					version: 0,
					msgId: message.msgId?message.msgId:String(new Date().valueOf()),
					timestamp: String(new Date().valueOf()),
					date: parseTime(new Date(parseInt(String(new Date().valueOf())))),
					payload: JSON.stringify(message)
				})
			)
            try {
                var packetId = userData.sendMessage(toUser, jsonMsg, bizType) //小米im发消息
                if (packetId) {
                    this.eventBus.$emit('sendMsgSuccess') //发送消息成功回调
                    if(isHello){
                        this.eventBus.$emit('say_hello_success') //招呼语回调
                    }
                }
            } catch (err) {
                console.log("sendMessage fail, err=" + err);
                this.$message.error('token过期，请重新刷新页面')
            }
		},
		fetchMIMCToken() {
			var sendData = {
				appId: this.mimc_appId,
				appKey: this.mimc_appKey,
				appSecret: this.mimc_appSecret,
				appAccount: this.mimc_appAccount
			}
			return this.httpRequestInter(
				'https://mimc.chat.xiaomi.net/api/account/token',
				sendData
			)
		},
		statusChange(bindResult, errType, errReason, errDesc) {
			if (bindResult) {
				console.log('login succeed')
				localStorage.setItem('testToken', userData.getToken())
				this.eventBus.$emit('loginSuccess');
			} else {
				console.log(
					'login failed.errReason=' +
					errReason +
					',errDesc=' +
					errDesc +
					',errType=' +
					errType
				)
			}
		},
		serverAck(packetId, sequence, timeStamp, errMsg) {
			// console.log(
			// 	'receive msg ack:' +
			// 	packetId +
			// 	',sequence=' +
			// 	sequence +
			// 	',ts=' +
			// 	timeStamp +
			// 	',error = ' +
			// 	errMsg
			// )
		},
		disconnect() {
			console.log('disconnect', this.userData)
            if(!this.imLogin()&&localStorage.getItem('uid')) {
                this.login()
            }
			// this.userData.login()
		},
		pullNotificationHandler(minSequence, maxSequence) {
			//拉取会话列表
			console.log('pull notification')
		},
		httpRequestInter(url, data) {
			var xhr = new XMLHttpRequest()
			xhr.open('POST', url, false)
			xhr.setRequestHeader('content-type', 'application/json')
			xhr.send(JSON.stringify(data))
			return JSON.parse(xhr.response)
		},
	}
}
export default imClient