import Vue from 'vue';
import Vuex from 'vuex';
import persistedState from 'vuex-persistedstate';
Vue.use(Vuex);
import { encrypt } from '@/utils/js/2222.js';

const store = new Vuex.Store({
  state: {
    width: 1600,
    height: 557,
    tableH: 600,
    accountType: 0, //账户安全中菜单点击id
    mainWidth: 0, //企业端body主体宽度
    bodyWidth: 0, //求职端body主体宽度
    sendText: '发送验证码',
    sendBtnDisable: false,
    sendSmsInterval: 60,
    showLoading: false,
    snedTimer: null,
    currMemut: '', //企业端菜单
    timer: null, //hash定时器
    companyStatus: '0', //账号状态
    isPower: false, //套餐是否有权限 vip
    isAudit: false, //企业是否认证
    isPersonAudit: false, //求职者是否认证
    isInfo: false, //是否完善企业信息
    layoutId: '1', //模板头部菜单id 默认首页
    isLogin: false, //个人是否登录
    isLogin2: false, //企业是否登录
    msgCount: 0,
    resumeCount: 0, //企业简历未读数量
    currCity: {
      //头部当前城市
      id: '',
      name: '全国站'
    },
    regStep: 1,
    isResumeReg: false, //是否新注册简历
    logo: '', //公司
    companyName: '', //
    isExchange: false, //是否双方回复
    isWx: '0', //是否交换微信
    isTel: '0', //是否交换电话
    userInfo: {
      // utype: '',
      // token: '',
      // mobile: '',
      // userId: '',
      userName: '',
      logo: ''
    }, //用户基本信息
    visitedViews: [],
    cachedViews: []
  },
  mutations: {
    WINDOW_SIZE: (state, wids) => {
      state.height = wids.h;
      state.width = wids.w;
      state.tableH = wids.h - 350;
      state.bodyWidth = state.width / 1.6;
      state.mainWidth = (state.width - 160) / 1.2;
    },
    setLoading(state, data) {
      state.showLoading = data;
    },
    //设置定时器
    setTimerStauts(state, data) {
      state.timer = data;
    },
    //设置账号安全菜单id
    setAccountMenu(state, data) {
      state.accountType = data;
    },
    //设置企业头部当前菜单
    setcurMenut(state, data) {
      state.currMemut = data;
    },
    //设置当前城市缓存
    setCurrCity(state, data) {
      state.currCity.id = data.id;
      state.currCity.name = data.name;
    },
    //清除本地存储
    clearLocation() {
      localStorage.removeItem('uid');
      localStorage.removeItem('tagCard');
      localStorage.removeItem('utype');
      localStorage.removeItem('hash');
      localStorage.removeItem('time');
      localStorage.removeItem('gg');
      localStorage.removeItem('gg2');
      localStorage.removeItem('isManager');
    },
    //设置登录状态
    setLogin(state, data) {
      state.isLogin = data;
    },
    //设置消息
    setMsg(state, data) {
      state.msgCount = data;
    },
    //设置企业简历未读数
    setResumeCount(state, data) {
      state.resumeCount = data;
    },
    //关闭hash检测定时器
    clearInter(state) {
      clearInterval(state.timer);
    },
    clearSms(state) {
      state.sendBtnDisable = false;
      state.sendText = '发送验证码';
      state.sendSmsInterval = 60;
      clearInterval(state.snedTimer);
    },
    //设置验证码状态
    SEND_CODE: (state) => {
      state.snedTimer = setInterval(() => {
        if (state.sendSmsInterval === 0) {
          state.sendBtnDisable = false;
          state.sendText = '发送验证码';
          state.sendSmsInterval = 60;
          clearInterval(state.snedTimer);
        } else {
          state.sendBtnDisable = true;
          state.sendSmsInterval--;
          state.sendText = state.sendSmsInterval + 's后重发';
        }
      }, 1000);
    },
    setCompanyLogo: (state, _params) => {
      state.logo = _params.logo;
      if (_params.name) {
        state.companyName = _params.name;
        localStorage.setItem('comname', state.companyName);
      }
    },
    //设置企业状态
    setCompanyStatus: (state, _params) => {
      if (_params.status) {
        state.companyStatus = _params.status; //1正常 2暂停
      }
      if (_params.isPower !== undefined) {
        state.isPower = _params.isPower; //套餐是否有权限
      }
      if (_params.audit !== undefined) {
        state.isAudit = _params.audit === '1'; //是否认证
      }
      if (_params.companyInfo !== undefined) {
        state.isInfo = _params.companyInfo !== '' && _params.companyInfo !== undefined; //是否完善信息
      }
    },
    //设置注册步骤
    setRegSetp: (state, _params) => {
      if (_params.regStep !== undefined) {
        state.regStep = _params.regStep;
      }
    },
    //设置个人状态
    setPersonStateus: (state, _params) => {
      if (_params.cid_audit) {
        state.isPersonAudit = _params.cid_audit === '1';
      }
      if (_params.isResumeReg !== undefined) {
        state.isResumeReg = _params.isResumeReg; //_params.isResumeReg   true
      }
    },
    setLogo: (state, data) => {
      state.userInfo.logo = data.logo;
      state.userInfo.userName = data.name;
      if (state.userInfo.userName) {
        localStorage.setItem('comname', state.userInfo.userName);
      }
    },
    // 设置双方回复
    sexExchange(state, data) {
      state.isExchange = data.isExchange;
    },
    //相当于同步的操作
    setChatInfo(state, data) {
      state.isWx = data.isWx;
      state.isTel = data.isTel;
    },
    ADD_VISITED_VIEW: (state, view) => {
      const index = state.visitedViews.findIndex((v) => {
        return v.path === view.path;
      });
      if (index <= -1) {
        state.visitedViews.push(
          Object.assign({}, view, {
            title: view.meta.title || 'no-name'
          })
        );
      }
    },
    ADD_CACHED_VIEW: (state, view) => {
      if (state.cachedViews.includes(view.name)) return;
      if (!view.meta.noCache) {
        state.cachedViews.push(view.name);
      }
    }
  },
  actions: {
    //窗体尺寸
    windowSize({ commit }, wids) {
      commit('WINDOW_SIZE', wids);
    },
    // 前端生成验证码
    sendWebCode(context) {
      let yzm = '';
      for (let i = 0; i < 4; i++) {
        yzm += Math.floor(Math.random() * 10);
      }
      return new Promise((resolve, reject) => {
        context.commit('SEND_CODE');
        resolve(yzm);
      });
    },
    //获取验证码无限制
    sendPhoneCode(context, _params) {
      let _parameter = {
        data: encrypt(JSON.stringify(_params))
      };
      return new Promise((resolve, reject) => {
        window.$vue.$showLoading('接口请求中...');
        window.$vue.$http
          .post('/ajax_phone', _parameter)
          .then((res) => {
            window.$vue.$closeLoading();
            if (res.success === '1') {
              context.commit('SEND_CODE');
              //调用接口成功
            }
            resolve(res);
          })
          .catch((error) => {
            window.$vue.$closeLoading();
            reject(error);
          });
      });
    },
    //获取验证码（验证、限制）
    sendCode(context, _params) {
      let _parameter = {
        data: encrypt(JSON.stringify(_params))
      };
      return new Promise((resolve, reject) => {
        window.$vue.$showLoading('接口请求中...');
        window.$vue.$http
          .post('/ajax', _parameter)
          .then((res) => {
            window.$vue.$closeLoading();
            if (res.success === '1') {
              context.commit('SEND_CODE');
              //调用接口成功
            }
            resolve(res);
          })
          .catch((error) => {
            window.$vue.$closeLoading();
            reject(error);
          });
      });
    },
    addView({ dispatch }, view) {
      dispatch('addVisitedView', view);
      dispatch('addCachedView', view);
    },
    addVisitedView({ commit }, view) {
      commit('ADD_VISITED_VIEW', view);
    },
    addCachedView({ commit }, view) {
      commit('ADD_CACHED_VIEW', view);
    }
  },
  plugins: [
    persistedState({
      storage: window.localStorage
    })
  ]
});

export default store;
